$red: #ff7070;
$orange: #ff9b58;
$yellow: #ffd258;
$green: #41e27f;
$blue: #75b5f1;
$purple: #9c8bff;
$pink: #fccacc;
$navy: #475275;
$dark: #33394d;
$darker: #2b3142;
$darkest: #262b3a;
$white: #ffffff;
$grey: #7e7e88;
$black: #1a1a1a;
$rainbow: linear-gradient($red, $orange, $yellow, $green, $blue, $purple);
$pastel: linear-gradient($pink, $blue);

$font-main: "Jua", sans-serif;
$font-size: 16px;