@import "../styles/global.scss";

.Timer {
  padding: 1.5rem 2rem 0rem 2rem;
  background-color: $dark;
  border-radius: 15px;
  border: 5px solid transparent;
  max-width: 42rem;
  width: 42rem;
  @include flex-center;
  margin-bottom: 0.5rem;

  h1 {
    line-height: 16rem;
    // border: 1px solid red;
  }

  &:hover {
    cursor: pointer;
    background-color: $darkest;
  }

  &.enabled {
    background-color: $darkest;
    border: 5px solid $dark;
  }

  &.shadow-focus h1 {
    text-shadow: 4px 4px 1px $green;
  }

  &.shadow-chill h1 {
    text-shadow: 4px 4px 1px $yellow;
  }

  &.shadow-bigChill h1 {
    text-shadow: 4px 4px 1px $red;
  }

  .minutes,
  .seconds,
  .colon {
    @include flex-center;
  }

  .minutes {
    justify-content: flex-end;
    width: 40%;
  }

  .seconds {
    justify-content: flex-start;
    width: 40%;
  }

  .colon {
    width: 10%;
    margin: 0 0.5rem;
  }

  /* Responsive Styles */
  @media screen and (max-width: 780px) {
    width: 38rem;

    h1 {
      font-size: 13rem;
      line-height: 15rem;
    }
  }

  @media screen and (min-width: 520px) and (max-width: 640px) {
    width: 35rem;
  }

  @media screen and (max-width: 520px) {
    width: 70vw;

    h1 {
      font-size: 25vw;
      line-height: 25vw;
      margin-bottom: 2vw;
    }
  }

  @media screen and (max-width: 400px) {
    h1 {
      margin-bottom: 4vw;
    }
  }
}