@import "./styles/global.scss";

.App {
  color: $white;
  height: 100vh;
  width: 100vw;
  min-height: 30rem;
  @include flex-center;
  @include custom-scrollbar;

  .foreground {
    opacity: 0.95;
    z-index: 1;
  }

  .background {
    position: fixed;
    z-index: 0;
  }

  /* Overlay Components */
  .overlay {
    position: fixed;
    top: 0;

    h2,
    h3,
    h4 {
      @include text-shadow;
    }

    .page-control,
    .close {
      margin-top: 2rem;
    }
  }

  .About,
  .Help {
    @include overlay;

    h2 {
      margin-bottom: 2rem;
    }

    p,
    span.key {
      border: 2px solid $white;
      border-radius: 10px;
      font-size: 0.9rem;
      font-weight: 500;
      min-width: 1.5rem;
      display: flex;
      @include flex-center;
      padding: 0.1rem 0.25rem 0 0.25rem;
      height: 1.5rem;
      background-color: rgba(255, 255, 255, 0.25);
      text-shadow: 1px 1px 1px $dark;
      box-shadow: 3px 3px 1px $dark;
      margin: 0 0.25rem;
    }

    p {
      padding: 1rem;
      margin: 2rem 0.5rem;
    }
  }

  .About {
    h4 {
      margin-top: 0.5rem;
    }

    p {
      display: block;
      height: initial;
      width: initial;
      font-size: 1.3rem;
      margin: 1rem;
    }
  }

  .Help {
    .controls {
      border: 2px solid white;
      border-radius: 5px;
      max-height: 70vh;
      padding: 0.5rem;
      @include custom-scrollbar;
    }

    h2 {
      margin-bottom: 1.5rem;
    }

    h3 {
      margin: 1rem 0 2rem 0;
    }

    h4 {
      font-size: 1.2rem;
      line-height: 2.5rem;
      @include flex-center;
      flex-wrap: nowrap;
    }

    span.option,
    span.value {
      min-width: 16rem;
      display: inline-flex;
      flex-wrap: nowrap;
    }

    span.option {
      justify-content: flex-end;
      align-items: center;
      margin-right: 1rem;
      min-width: 11rem;
    }

    span.value {
      justify-content: flex-start;
    }

    /* Responsive Styles */
    @media screen and (max-width: 400px) {
      .controls {
        width: 85vw;
        overflow-x: hidden;
      }

      h4 {
        @include flex-center-col;
        margin: 1rem 0;

        span.option,
        span.value {
          justify-content: center;
        }
      }
    }
  }

  /* Nav Bar */
  header.nav {
    position: absolute;
    top: 0;
    @include flex-center;
    justify-content: space-between;
    padding: 0.5rem;
    width: 100vw;
    z-index: 4;

    .nav-left,
    .nav-right {
      display: flex;
      justify-content: space-between;

      h4 {
        width: 6rem;
        opacity: 0.8;

        &.selected {
          opacity: 1;
          font-size: 1.6rem;
        }

        &:hover {
          opacity: 1;
        }

        &:last-child {
          margin-right: 0;
          margin-left: 0.8rem;
        }
      }

      .sound-toggle {
        font-size: 2.3rem;
        margin-right: 1rem;
        opacity: 0.7;
        color: $dark;
        height: 2.5rem;
        width: 2.5rem;
        @include flex-center;

        &.active {
          opacity: 0.8;
          color: $white;
        }

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }

    }


    /* Responsive Styles */
    @media screen and (max-width: 960px) {
      left: auto;
      right: auto;
    }
  }

  /* Main Display */
  section.display {
    padding: 2rem 0 6rem 0;
    @include flex-center-col;

    h2.title {
      padding-top: 4rem;
    }

    h3.mode {
      font-size: 3rem;
      margin-top: 0.5rem;

      span {
        margin: 0 0.5rem 0.4rem 0.5rem;
      }
    }

    footer {
      height: 6rem;
      cursor: pointer;

      &:hover {
        h3 {
          font-size: 3.2rem;
        }

        h4 {
          font-size: 1.7rem;
        }
      }
    }

    .alert-container {
      height: 1rem;
      width: fit-content;

      .alert {
        opacity: 0;

        &.alert-3 {
          opacity: 1;
          transform: translateY(5px);
        }

        &.alert-2 {
          opacity: 1;
          transform: translateY(5px);
        }

        &.alert-1 {
          opacity: 0;
          transition: 0.5s;
        }
      }

      .tip {
        @include flex-center;
        align-items: flex-end;
        margin-top: 0.5rem;
        max-width: 90vw;

        h4 {
          animation: pulse 2s infinite;
        }

        @keyframes pulse {
          0% {
            transform: none;
          }

          10% {
            transform: scale(1.05);
          }

          20% {
            transform: none;
          }

          30% {
            transform: scale(1.05);
          }

          40% {
            transform: none;
          }

          100% {
            transform: none;
          }
        }
      }

    }
  }

  .focus {
    color: $green;
  }

  .chill {
    color: $yellow;
  }

  .bigChill {
    color: $red;
  }

  /* Keypress Styling */
  &.shift-down .Timer {
    border-color: $white;
  }

  &.shift-focus .Timer h1 {
    color: $green;
    text-shadow: 4px 4px 1px $white;
  }

  &.shift-chill .Timer h1 {
    color: $yellow;
    text-shadow: 4px 4px 1px $white;
  }

  &.shift-bigChill .Timer h1 {
    color: $red;
    text-shadow: 4px 4px 1px $white;
  }
}