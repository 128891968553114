@import "./styles/global.scss";

html {
  font-size: $font-size;
  user-select: none;

  /* Responsive Styles */
  @media screen and (min-width: 640px) and (max-width: 780px) {
    font-size: 14px;
  }

  @media screen and (min-width: 0px) and (max-width: 640px) {
    font-size: 13px;
  }
}

body {
  margin: 0;
  font-family: $font-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  min-height: fit-content;
}

a {
  text-decoration: none;
  color: $white;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  text-align: center;
  max-width: 100vw;

  &:not(h1) {
    text-shadow: 3px 3px 1px $dark;
  }
}

h1 {
  font-size: 15rem;
  line-height: 15rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

p {
  font-size: 1.5rem;
  text-align: center;
  max-width: 47rem;
  margin: 1rem;

  span {
    font-weight: 600;
  }
}