@import "../styles/global.scss";

.Settings {
  @include overlay;

  h2 {
    margin-bottom: 2rem;
  }

  h5 {
    font-size: 1.2rem;
    text-align: center;
    margin: 2rem 1rem 1rem 1rem;
  }

  .current,
  .elapsed,
  .total,
  .settings-page {

    span.option,
    span.value {
      min-width: 11rem;
      flex-wrap: nowrap;
    }

    span.option {
      text-align: right;
      margin-right: 1rem;
    }

    span.value {
      text-align: initial;
    }
  }

  .current,
  .elapsed,
  .total {
    &:hover {
      cursor: initial;
      font-size: 1.5rem;
    }
  }

  .elapsed,
  .total {
    margin-bottom: 2rem;
  }

}