@import "../styles/global.scss";

.Milk {

  .liquid {
    width: 300%;
    position: fixed;
    transition: 2s;
    transition-timing-function: cubic-bezier(0, 0, 0, 1.2);
    animation-timing-function: cubic-bezier(0, 0, 0, 1.2);
    box-shadow: 0px 0px 1px 0px $white;

    &.one {
      animation: animate 6s infinite;
      left: -100vw;
      opacity: 0.7;
      bottom: -100vh;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      &.two {
        bottom: -99vh;
        animation-delay: 1s;
      }
    }

    &.liquid-white {
      background: linear-gradient($white, transparent);

      &.flat {
        opacity: 0.8;
        background: linear-gradient($white, $white);
      }
    }

    &.liquid-red {
      background: linear-gradient($red, $white);

      &.flat {
        background: linear-gradient($red, $red);
      }
    }

    &.liquid-orange {
      background: linear-gradient($orange, $white);

      &.flat {
        background: linear-gradient($orange, $orange);
      }
    }

    &.liquid-yellow {
      background: linear-gradient($yellow, $white);

      &.flat {
        background: linear-gradient($yellow, $yellow);
      }
    }

    &.liquid-green {
      background: linear-gradient($green, $white);

      &.flat {
        background: linear-gradient($green, $green);
      }
    }

    &.liquid-blue {
      background: linear-gradient($blue, $white);

      &.flat {
        background: linear-gradient($blue, $blue);
      }
    }

    &.liquid-purple {
      background: linear-gradient($purple, $white);

      &.flat {
        background: linear-gradient($purple, $purple);
      }
    }

    &.liquid-pink {
      background: linear-gradient($pink, $white);

      &.flat {
        background: linear-gradient($pink, $pink);
      }
    }

    &.liquid-navy {
      background: linear-gradient($navy, $white);

      &.flat {
        background: linear-gradient($navy, $navy);
      }
    }

    &.liquid-grey {
      background: linear-gradient($grey, $white);

      &.flat {
        background: linear-gradient($grey, $grey);
      }
    }

    &.liquid-black {
      background: linear-gradient($black, $white);

      &.flat {
        background: linear-gradient($black, $black);
      }
    }
  }

  &.off {
    display: none;
  }

  &.loading {
    .liquid {

      &.one,
      &.two {
        bottom: -200vh;
      }
    }
  }

  &.flat {
    .liquid {
      animation: none;
      transition: 2s;
      transition-timing-function: linear;
      opacity: 1;
      filter: brightness(110%);
    }
  }

  @keyframes animate {
    0% {
      transform: translateX(-15vw) rotate(-1deg);
    }

    50% {
      transform: translateX(15vw) rotate(1deg);
    }

    100% {
      transform: translateX(-15vw) rotate(-1deg);
    }
  }
}